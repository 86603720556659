/* BLOGTRACER V.1 (2021) - by Ali Eren GUNER */
:root {
  --app-width: 98%;
  --app-padding: 100px;
  --primary-color: #0F6AFF;
  --primary-dark-color: rgba(0, 0, 0, 0.28);
  --white-color: rgba(255,255,255, 0.9);
  --side-menu-width: 280px;
}

body {
  margin: 0;
  font-family: 'Poppins', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #ebedf1;
  overflow: hidden;
}

h4 {
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.8);
}

table {
  margin-top: 20px;
  padding: 1%;
  width: 100%;
}

table > tr > td {
  padding-left: 10px;
}

table th, table td {
  border: 1px solid black;
}

textarea[class*="textbox"] {
  resize: vertical;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #888;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}

/* BOX */
.box {
  background-color: #FFF;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

/* CONTAINERS */
.App {
  margin: 0 auto;
  width: var(--app-width);
  max-width: 1505px;
}

.content-wrapper {
  position: fixed;
  top: 76px;
  padding-right: 10px;
  margin-left: calc(var(--side-menu-width) + 10px);
  width: calc(100% - var(--side-menu-width) - 50px);
  max-width: calc(1505px - var(--side-menu-width) );
  height: calc(100% - 90px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.content-wrapper > div {
  margin-bottom: 8px;
  padding: 10px;
}

.grid-container > div {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 10px;
}

.grid-container > h3 {
  margin: 10px 0px 10px 0;
  padding-left: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

.grid-container button {
  margin-left: 75%;
  width: 25%;
  padding: 5px 20px 5px 20px;
  font-size: 0.9em;
}

.grid-item {
  position: relative;
  box-sizing: border-box;
  border: 2px solid rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  cursor: pointer;
}

.grid-item:hover {
  border: 2px solid rgba(0, 0, 0, 0.18);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.grid-item:hover .content-image > img {
  transition: opacity 0.3s ease;
  opacity: 0.8;
}

.grid-item::before {
  content: '';
  display: block;
}

.grid-item .content {
  position: relative;
  /* top: 0; left: 0; */
  height: 100%;
  width: 100%;
}

/* https://stackoverflow.com/questions/46548987/a-grid-layout-with-responsive-squares */

/* NAVIGATION BAR */
#navigation {
  position: fixed;
  top: 8px; left: 0; right: 0; bottom: 0;
  margin: 0 auto;
  width: 98%;
  max-width: 1505px;
  height: 60px;
  /* border-radius: initial; */
  user-select: none;
}

.navigation-account {
  position: relative;
  float: right;
  right: 20px;
}

.navigation-account a {
  padding-right: 20px;
}

.navigation-account span {
  position: relative;
  left: 2px;
  top: -4px;
  font-weight: 600;
  font-size: 0.8em;
  color: rgba(0, 0, 0, 0.5);
  transition: color 0.5s ease;
}

.navigation-account span:hover {
  color: rgba(0, 0, 0, 0.6);
}

#navigation ul, .mobile-nav ul {
  display: block;
  margin: 0;
  list-style-type: none;
}

#navigation li, .mobile-nav li{
  float: left;  
  margin-top: 8px;
  margin-right: 10px;
  border-radius: 15px;
  background-color: rgba(194, 194, 194, 0.1);
  text-align: center;
}

#navigation li a, .mobile-nav li a {
  text-decoration: none;
}

#navigation li i, .mobile-nav li i {
  padding: 8px 10px 10px;
  font-size: 1.6em;
  color: rgba(0, 0, 0, 0.4);
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: rgba(194, 194, 194, 0.15);
  border-radius: 20px;
  transition: all 0.2s ease;
}

#navigation li i:hover, .mobile-nav li i:hover {
  color: rgba(0, 0, 0, 0.5);
  background-color: rgba(194, 194, 194, 0.2);
}

.navigation-logo {
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  top: -1px;
  width: 40px;
  background-color: var(--primary-color);
  border-radius: 10px;
}

.navigation-splitter {
  position: relative;
  top: 4px;
  width: 2px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.05);
}

#navigation .active i, .mobile-nav .active i {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}

.mobile-nav {
  position: fixed;
  width: var(--app-width);
  height: 50px;
  padding: 0 0 10px 0;
  left: calc((100% - var(--app-width)) / 2 );
  bottom: 24px;
  z-index: 1;
  transform: scale(0.9);
}

.mobile-nav #navigation-button {
  width: fit-content;
  padding-left: 0;
  margin: auto;
}

/* SIDE NAVIGATION SECTION*/
#side-navigation {
  position: fixed;
  margin-top: 76px;
  float: left;
  width: var(--side-menu-width);
  height: calc(100% - 90px);
}

#side-navigation-photo {
  margin: 30px auto;
  width: 100px;
  height: 100px;
}

#side-navigation-photo img {
  border-radius: 100%;
  border-width: 4px;
  border-style: solid;
  border-color: var(--primary-color);
  background: var(--primary-color);
  width: 100%;
}

#side-navigation-main {
  margin: auto;
  width: 80%;
}

#side-navigation-main > div, #content-page > div {
  margin-top: -10px;
  text-align: center;
}

#side-navigation-main span, #content-page span {
  display: block;
  margin: 0;
}

#side-navigation-main span:last-child, #content-page span:last-child {
  margin-top: -25px;
}

#side-navigation-main ul {
  margin-left: -20px;
}

#side-navigation-main li {
  list-style-type: none;
  font-weight: 500;
  margin-bottom: 3px;
  width: 90%;
  padding-top: 5px;
  padding-left: 2px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

#side-navigation-main li > a {
  width: 100%;
  color: #444;
  text-decoration: none;
  transition: color 0.3s ease;
}

#side-navigation-main li > a:hover {
  color: #000;
}

#side-navigation-main li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

#side-navigation-main a > i {
  width: 25px;
  height: 25px;
  font-size: 1.2em;
  text-align: center;
}

/* SEARCHBOX */
#nav-search {
  position: relative;
  left: 0;
  transition: all 0.3s ease;
}

.searchbox {
  float: left;
  width: 25%;
}

.searchbox input {
  position: relative;
  left: -55px;
  margin-top: 10px;
  width: 100%;
  height: 35px;
  padding-left: 10px;
  float: left;
  border-radius: 10px;
  border: none;
  border: 2px solid transparent;
  background-color: rgba(194, 194, 194, 0.1);
  font-size: 1em;
  display: none;
}

.searchbox input:focus {
  display: block;
  border: 2px solid rgba(194, 194, 194, 0.4);
  outline: 0;
}

/* BLOG CARD */
.grid-item .content-image {
  margin: 5%;
  width: 90%;
  min-height: 100px;
  
}

.grid-item .content-image > img {
  width: 100%;
  height: 100%;
}

.grid-item .content > div > h4, .grid-item .content > div > h3 {
  margin-left: 10px;
}

.grid-item .content > div > h4 {
  margin-top: -18px;
  font-size: 0.9em;
}

.grid-item .content > div > h3 {
  margin-top: 0;
  font-size: 1em;
}

.grid-item .content > div > h3:hover {
  text-decoration: underline;
  cursor: pointer;
}

.grid-item .content .blogcard-bottom > div {
  padding-left: 10px;
}

.grid-item .content .blogcard-bottom img {
  float: left;
  width: 30px;
  border-radius: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.grid-item .content a {
  float: left;
  margin-left: 5px;
  margin-top: 5px;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  font-size: 0.75em;
}

.grid-item .content a:hover {
  color: var(--primary-color);
}

.grid-item .content .blogcard-statistics {
  float: right;
}

.grid-item .content .blogcard-statistics > div {
  float: right;
  margin-top: 5px;
  margin-right: 10px;
  padding-bottom: 20px;
  font-weight: 600;
  font-size: 0.75em;
  color: #222;
  text-decoration: none;
}

/* FOOTER */
.footer {
  text-align: center;
}

.footer p {
  font-size: 0.9em;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7  );
}

/* COMMENT CARD */
.comment-card {
  background-color: white;
  border-radius: 20px;
  border: 2px solid rgba(0, 0, 0, 0.08);  
}

.comment-card .comment-photo {
  margin: 15px;
  width: 100px;
  height: 100px;
}

.comment-card .comment-photo img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.comment-card .comment-content {
  margin-top: -110px;
  margin-left: 130px;
  width: calc(100% - 150px);
  padding-bottom: 30px;
}

.comment-card .comment-owner {
  font-weight: 500;
}

.comment-card .comment-body {
  font-size: 0.95em;  
}

.comment-card .comment-details > button {
  margin-top: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 25px;
  color: rgba(0, 0, 0, 0.6);
}

.comment-card .comment-details > button i {
  margin-right: 3px;
}

/* PAGES */
/* HOME PAGE */
.no-content {
  display: block;
  padding-left: 10px;
  margin-bottom: 30px;
}

/* PROFILE PAGE */
.full-size {
  position: absolute;
  right: 0; bottom: 0; left: 10px;
  margin: 0 auto;
  width: var(--app-width);
  max-width: 1505px;
  transition-property: left, width;
  transition-duration: 0.5s;
  transition-timing-function: linear;
}

#profile-page {
  padding-left: calc((100% - var(--side-menu-width) - 50px) /8);
  padding-right: calc((100% - var(--side-menu-width) - 50px) /8);
}

#profile-page .menu {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

#profile-page .menu button {
  background-color: transparent;
  border: none;
  font-size: 0.95em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  margin-right: 10px;
}

#profile-page .menu button:not(.active) {
  color: #000;
}

#profile-page .menu button:not(.active):hover {
  color: #555;
}

#profile-page .menu .active {
  border-bottom: 2px solid var(--primary-color);
  color: var(--primary-color);
}

#profile-page .grid-container > div {
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 30px;
}

#profile-page .profile-card {
  display: block;
  margin-bottom: 40px;
  padding: 10px 10px 50px 10px;
  height: fit-content;
  min-height: 150px;
  background-color: rgba(0, 0, 0, 0.02);
  border: 2px solid rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}

#profile-page .profile-card .profile-photo {
  position: relative;
  left: 10px;
  top: 10px;
  max-width: 200px;
  width: 200px;
}

#profile-page .profile-card .profile-photo img {
  width: 100%;
  height: 100%;
  min-height: 150px;
  border-radius: 30px;
  border: 4px solid var(--primary-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  background-color: var(--primary-color);
}

#profile-page .profile-card .profile-photo button {
  margin-top: 10px;
  width: calc(100% + 10px); 
  height: 30px; 
}

#profile-page .profile-card .profile {
  position: absolute;
  display: block;
  top: 30px;
  margin-left: 250px;
}

#profile-page .profile h3 i {
  color: var(--primary-color);
}

#profile-page .profile h4 {
  margin-top: -20px;
  font-size: 1.1em;
  font-weight: 400;
}


#profile-page .profile p {
  width: 80%;
  font-weight: 400;
}

#profile-page .profile .link {
  display: inline-block;
  margin-bottom: 2px;
  font-size: 1em;
  font-weight: 400;
}

#profile-page .profile .link:hover {
  color: #0F6AFF;
}

#profile-page .profile .link i {
  position: relative;
  top: 1px;
  font-size: 1.1em;
  font-weight: 500;
}

#profile-page .statistics {
  text-align: center;
}


#profile-page .statistics i {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 5em;
  color: #0F6AFF;
}

#profile-page .statistics .rotate {
  animation:spin 1.5s linear;
}

@keyframes spin { 
  100% { 
    -webkit-transform: rotate(360deg); 
    transform:rotate(360deg); 
  } 
}


/* CONTENT PAGE */
#content-page .content {
  float: left;
  width: calc(100% - var(--side-menu-width) - 50px);
  padding-bottom: 100px;
}

#content-page .content > h3 {
  margin-top: 30px;
  padding-bottom: 20px;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

#content-page .content > .interactive-buttons {
  margin: 0 auto;
  width: 90%;
  text-align: left;
}

#content-page .content > .interactive-buttons > .button-secondary {
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  margin-right: 5px;
  font-size: 1.3em;
}

#content-page .content > .interactive-buttons span {
  display: inline-block;
  position: relative;
  padding-left: 8px;
  bottom: 0.1em;
  font-size: 0.8em;
  font-weight: 500;
}

#content-page p {
  margin: 20px;
}

#content-page .profile-card {
  position: fixed;
  margin-top: 20px;
  top: 76px;
  right: 30px;
  width: var(--side-menu-width); 
  height: calc(100% - 150px);
  border-left: 2px solid rgba(0, 0, 0, 0.08);
}

#content-page .profile-photo {
  margin: 10px auto;
  width: 100px;
} 

#content-page .profile-photo img {
  border-radius: 100%;
  border-width: 4px;
  border-style: solid;
  width: 100%;
}

#content-page .profile-card button {
  padding: 5px 20px 5px 20px;
}

#content-page .userName:hover {
  text-decoration: underline;
  cursor: pointer;
}

#content-page .profile-card > button {
  margin: 10px 0;
  height: 25px;
}

#content-page .profile-bio {
  margin: 10px auto;
  width: 90%;
  max-height: calc((100% - 175px) - 150px);
  font-size: 0.9em;
  overflow-x: hidden;
  overflow-y: hidden;
  cursor: default;
}

#content-page .profile-bio:hover {
  overflow-y: auto;
}

#content-page .content-details > div {
  width: fit-content;
  margin: 40px auto;
  color: rgba(0, 0, 0, 0.85);
}

#content-page .content-details > div > div {
  float: left;
  padding: 8px;
  margin: -1px;
  border: 2px solid rgba(0, 0, 0, 0.08);
}

#content-page .content-details > div > div:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

#content-page .content-details > div > div:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

#content-page .content > button {
  margin: 20px auto;
  width: 90%;
}

#content-page .comment {
  margin: 20px auto;
}

#content-page .comment, #content-page .comment-wrapper {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.03);
  padding-bottom: 10px;
}

#content-page .comment {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

#content-page .comment-wrapper {
  margin: -20px auto;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

#content-page .comment > h4 {
  text-align: left;
  padding-top: 15px;
  margin-left: 15px;
}

#content-page .comment textarea {
  margin: 10px auto;
  width: calc(100% - 20px);
  height: 150px;
  background-color: white;  
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

#content-page .comment > button {
  margin-left: calc(70% - 20px);
  margin-top: -15px;
  width: 30%;
}

#content-page .comment-card {
  text-align: initial;
  margin: 0 auto;
  width: calc(100% - 20px);
}

#content-page .comment-card:not(:first-child) {
  margin-top: 8px;
}

/* CONTENTS PAGE */
#contents-page .box > h3, #following-page h3, #content-creation-page h3 {
  margin: 10px 0px 10px 0;
  padding-left: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.08);
}

#categories .category-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 10px;
}

#categories .category-item {
  position: relative;
  box-sizing: border-box;
  border-radius: 10px;
  height: 110px;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.2);
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

#categories .category-item i {
  margin-top: 0.3em;
  width: 100%;
  font-size: 3em;
  text-align: center;
  transition: all 0.5s ease-in-out;
}

#categories .category-item span {
  display: block;
  margin-top: 5px;
  transition: all 0.5s ease;
}

#categories .category-item:hover i {
  filter: blur(2px);
  font-size: 4em;
  opacity: 0.35;
}

#categories .category-item:hover span {
  transform: translateY(-50px);
  letter-spacing: .2rem;
  filter: brightness(120%)
}

#categories h3 i {
  padding: 6px 15px 6px 15px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
}

#categories button:hover > i {
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.8);
}

/* CONTENT CREATION PAGE */
#content-creation-page {
  padding-bottom: 80px;
}

#content-creation-page h4 {
  margin-left: calc(10%);
}

#content-creation-page > div {
  margin: 30px auto;
  width: 80%;
  border-top: 10px solid var(--primary-color);
  border-left: 2px solid rgba(0, 0, 0, 0.05);
  border-right: 2px solid rgba(0, 0, 0, 0.05);
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding-bottom: 30px;
}

#content-creation-page .textbox {
  margin-top: 20px;
  margin-left: calc(10% - 5px);
  width: 80%;
}

#content-creation-page textarea {
  height: 200px;
}

#content-creation-page button:first-of-type {
  margin-right: 10%;
}

#content-creation-page button {
  float: right;
  margin-top: -5px;
  margin-left: 10px;
  padding: 5px 10% 5px 10%;
}

#content-creation-page .button-secondary {
  margin-top: 20px;
}

#content-creation-page .tag-wrapper {
  margin-top: 80px;
  margin-left: calc(10% - 5px);
  width: calc(80% + 10px);
  background-color: rgba(0, 0, 0, 0.01);
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 20px;
}

#content-creation-page .tag {  
  display: inline-block;
  margin: 5px;
  padding: 10px 10px 10px 20px;
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.03);
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

#content-creation-page .tag span {
  position: relative;
  margin-left: 6px; 
}

#content-creation-page #tag-control {
  float: right;
  margin-top: -5px;
  margin-right: 10%;
}

#content-creation-page .tag .delete {
  position: relative;
  margin-left: 10px;
  padding: 5px 10px 5px 10px;
  background-color: rgba(0, 0, 0, 0.06);
  color: rgba(0, 0, 0, 0.8);
  border-radius: 10px;
  font-size: 0.8em;
  cursor: pointer;
}

#content-creation-page .tag .delete:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

#content-creation-page .input-file {
  position: absolute;
  margin-top: 20px;
  margin-left: calc(9% - 10px);
  padding-top: 5px;
  padding-left: 20px;
  width: 60%;
  height: 30px;
  background-color: var(--primary-color);
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease; 
}

#content-creation-page .input-file:hover {
  opacity: 0.85;
}

#content-creation-page #contentPhoto {
  visibility: hidden;
}

#content-creation-page #contentPhotoPreview {
  margin-left: calc(100% / 2 - 150px);
  width: 300px;
  border-radius: 10px;
  border: 2px solid rgba(194, 194, 194, 0.1);
}

#content-creation-page #success-message {
  padding-top: 10px;
  height: calc(8em - 1.8vw);
  padding-bottom: 5px;
}

#content-creation-page #success-message i {
  float: left;
  margin-left: 20px;
  font-size: 5em;
  color: #43A047;
}

#content-creation-page #success-message p {
  float: left;
  margin-left: 15px;
  margin-top: 1.8%;
  width: 70%;
  font-weight: 500;
}

/* FOLLOWING PAGE */
#user-list td, #user-list th {
  border: none;
  border-bottom: 1px solid #888;
  margin: 0;
}

#user-list tr {
  text-align: center;
  font-size: 00.85em;
}

#user-list td img {
  width: 60px;
  border-radius: 100%;
}

/* LOGIN PAGE */
#login-form, #register-form {
  position: absolute;
  left: 0; top: 0; right: 0;
  margin: 100px auto;
  padding-top: 20px;
  padding-bottom: 30px;
  width: 30%;
  min-width: 400px;
  transition: height 0.3s ease;
  user-select: none;
}

#login-form #form-logo, #login-form #user-photo {
  margin: auto;
  display: block;
  border-radius: 100%;
}

#login-form #form-logo {
  width: 80px;
  height: 80px;
  padding: 5px 10px 15px 12px;
  background-color: var(--primary-color);
}

#login-form #user-photo {
  padding: -1px;
  width: 100px;
  height: 100px;
  border: 4px solid var(--primary-color);
  background-color: var(--primary-color);
}

#login-form .textbox-inverted, #register-form .textbox-inverted {
  margin-left: 10%;
  width: 80%;
  height: 32px;
  user-select: auto;
}

#login-form button, #register-form button{
  margin-top: 20px;
  margin-left: 10%;
  width: 81%; 
  font-family: inherit;
}

#login-form a, #register-form a {
  margin: 30px auto;
  display: block;
  width: 50%;
  text-align: center;
  animation: fadein 2s;
}

/* COMPONENTS */
/* INPUT: STANDARD */
.textbox {
  height: 50px;
  padding-left: 10px;
  border-radius: 10px;
  color: rgba(0, 0, 0, 0.7);
  border: 1px solid rgba(194, 194, 194, 0.5);
  background-color: rgba(194, 194, 194, 0.05);
  font-size: 1em;
  font-family: inherit;
  transition: all 0.2s ease;
}

.textbox:focus {
  outline: 0;
  color: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(100, 100, 100, 0.5);
}

/* BUTTONS */
.button-primary {
  background-color: var(--primary-color);
  height: 40px;
  font-weight: 600;
  border: 0px;
  border-radius: 10px;
  outline: none;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  color: var(--white-color);
  transform: translateY(0);
  transition-property: transform, box-shadow, opacity;
  transition-duration: 0.2s; 
  transition-timing-function: ease;
  cursor: pointer;
}

.button-primary:hover {
  transform: translateY(-2px);
  color: rgba(255,255,255, 0.95);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.28);
  opacity: 0.95;
}

.button-secondary {
  background-color: rgba(0, 0, 0, 0.05);
  height: 40px;
  font-weight: 600;
  border: 0px;
  border-radius: 10px;
  outline: none;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.2s ease;
  cursor: pointer;
} 

.button-secondary:hover {
  background-color: rgba(0, 0, 0, 0.08);
  color: rgba(0, 0, 0, 0.8);
}

/* INVERTED TEXTBOX */
.textbox-inverted {
  margin-top: 10px;
  padding-bottom: 8px;
  height: fit-content;
  border: 2px solid rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  cursor: text;
  color: rgba(0, 0, 0, 0.8);
  transition: height 0.3s ease;
}

.textbox-inverted > span {
  display: inline-block;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 0.85em;
  vertical-align: middle;
  font-weight: normal;
  cursor: initial; 
  transform: translateY(0);
  transition: transform 0.2s ease;
}

.textbox-inverted > b {
  display: block;
  float: left;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 0.85em;
  cursor: initial; 
  transform: translateY(0);
  transition: transform 0.2s ease;
}

.textbox-inverted > input {
  position: relative;
  top: -5px;
  width: calc(100% - 20px);
  margin-left: 8px;
  padding-left: 2px;
  outline: none;
  border: none;
  transition: opacity 0.2s ease;
}

.textbox-inverted > input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.textbox-inverted > input:focus {
  opacity: 1 !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.textbox-button {
  float: right;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
  top: -12px;
  right: 10px;
  position: relative;
  width: 30px !important;
  border: none; 
  border-radius: 5px;
  transition: color 0.3s ease;
  cursor: pointer;
}

.textbox-button:hover {
  color: rgba(0, 0, 0, 0.8);
}

/* Link */
.link {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.8em;
  color: #444;
  transition: color 0.2s ease;
  cursor: pointer;
}

.link:hover {
  color: var(--main-color);
}

/* ALERT */
.alert {
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: 15px auto;
  width: 40%;
  height: 40px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  z-index: 1;
}

.alert > p {
  margin-top: 8px;
  font-weight: 500;
  font-size: 0.9em;
  padding-left: 20px;
}

.alert-error {
  background-color: rgba(248, 215, 218, 1);
  border-color: rgba(220, 53, 69, 1);
  color: rgba(114, 28, 36,1);
}

.alert-success {
  background-color:#DCEDC8;
  border-color: rgb(52, 190, 52);
  color: #459227;
}



/* Responsive */
@media only screen and (max-width: 860px) {
  /* For tablets: */
  #side-navigation {
    display: none;
  }

  .content-wrapper {
    margin-left: calc( (100% - var(--app-width)) / 2);
    width: var(--app-width);
  }

  #navigation li {
    display: none;
  }
  
  #navigation li:nth-child(1), #navigation li:nth-child(2), #navigation li#nav-search {
    display: block;
  }
  
  .navigation-logo {
    margin-left: -20px;
  }

  .footer {
    font-size: 0.85em;
  }
}

@media only screen and (min-width: 860px) {
  .mobile-nav {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  #content-page .content {
    width: calc(100% - 20px);
  }

  #content-page .profile-card {
    margin: 20px auto;
    padding-bottom: 100px;
    padding: 30px 0 90px 0;
    right: 0;
    top: 0;
    position: relative;
    border: 2px solid rgba(0, 0, 0, 0.03);
    width: 90%;
    height: auto;
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 20px;
  }

}